<template>
    <div>
        <!-- begin:: Content Head -->
        <KTSubheader v-bind:title="'File Manager'">
            <template v-slot:button-content></template>
        </KTSubheader>
        <!-- end:: Content Head -->
        <!--begin::iFrame-->
        <iframe v-if="show" v-bind:src="settings.baseUrl" v-bind:style="{
            width: '100%',
            height: '800px',
            border: 'none'
        }"></iframe>
        {{ settings.baseUrl }}
        <!--end::iFrame-->
    </div>
</template>
  
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";

export default {
    name: "iFrameFileManager",
    components: {
        KTSubheader
    },
    data: () => ({
        settings: {
            baseUrl: process.env.VUE_APP_FILE_MANAGER_API, // overwrite base url Axios
        },
        show: false
    }),
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: "File Manager" }]);
        this.show = true;
    }
};
</script>
  
<style scoped>
[title~="About"] {
    display: none;
}
</style>
  